/* main page */
.page {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 19px;
}

.page-title {
  font-size: 48px;
  font-weight: 600;
  color: #000000;
  line-height: 58px;
  margin-top: 70px;
  margin-bottom: 16px;
}

@media screen and (max-width: 575.98px) {
  .page-title {
    font-size: 36px;
    line-height: 44px;
    margin-top: 40px;
  }
}

.page-price-tips {
  margin: 54px 0 24px;
}

@media screen and (max-width: 575.98px) {
  .page-price-tips {
    margin: 40px 0 24px;
  }
}

.input-group {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  line-height: 29px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-group + .input-group {
  margin-top: 40px;
}

.input-group > input {
  width: 128px;
}

@media screen and (max-width: 575.98px) {
  .input-group {
    font-size: 20px;
    line-height: 24px;
    gap: 12px;
  }

  .input-group + .input-group {
    margin-top: 32px;
  }

  .input-group > input {
    width: 100%;
    text-align: center;
  }
}

.segmented-control {
  display: flex;
  margin-bottom: 32px;
}

@media screen and (max-width: 575.98px) {
  .segmented-control {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
}

.cards-wrapper {
  margin-top: 54px;
}

@media screen and (max-width: 575.98px) {
  .cards-wrapper {
    margin-top: 40px;
  }
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 15px;
}

@media screen and (max-width: 575.98px) {
  .pricing-cards {
    flex-direction: column;
  }
}

.faq {
  font-size: 36px;
  font-weight: 600;
  color: #000;
  line-height: 43px;
  padding: 96px 24px 32px;
}

@media screen and (max-width: 575.98px) {
  .faq {
    font-size: 20px;
    line-height: 24px;
    padding: 44px 12px 34px;
  }
}

.brands {
  padding: 64px 0px 121px;
}

.brands__title {
  font-size: 24px;
  font-weight: 400;
  color: #000;
  line-height: 29px;
  text-align: center;
}

.brands__images {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 24px;
  flex-wrap: wrap;
}

.brands__images > img {
  width: auto;
  height: 44px;
}

@media screen and (max-width: 575.98px) {
  .brands__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .brands__images {
    gap: 20px;
  }

  .brands__images > img {
    height: 36px;
  }

  /* 仅在移动端隐藏 */
  .brands--hidden {
    display: none;
  }
}

/* Feature */
.feature {
  display: flex;
  gap: 0.5em;
}

.feature--disabled {
  display: none;
}

@media screen and (max-width: 575.98px) {
  .feature--disabled {
    display: inherit;
    color: #8c8c8c;
  }
}

/* RoundedButton */
.rounded-button {
  line-height: 44px;
  background: #222222;
  border-radius: 22px;
  padding: 0 20px;
  color: white;
  width: fit-content;
  cursor: pointer;
  font-weight: 600;
  user-select: none;
}

.rounded-button:hover {
  opacity: 0.8;
}

.rounded-button:active {
  opacity: 0.6;
}

/* PricingCard */
.pricing-card {
  width: calc((100% - 30px) / 3);
  border-radius: 12px;
  border: 1px solid #e6e6e6;

  overflow: hidden;
  background-color: #f5f5f5;
}

@media screen and (max-width: 575.98px) {
  .pricing-card {
    width: 100%;
  }
}

.pricing-card__header {
  padding: 36px 24px 24px;
  background-color: white;
}

.pricing-card__body {
  padding: 24px 24px 36px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pricing-card__title {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  line-height: 29px;
}

.pricing-card__price {
  display: flex;
  align-items: center;
  gap: 24px;
}

.pricing-card__price-value {
  font-size: 56px;
  font-weight: 600;
  color: #000000;
  line-height: 67px;
}

.pricing-card__price-unit {
  font-size: 24px;
  font-weight: 600;
  color: #bebebe;
  line-height: 29px;
}

/* Question */
.question {
  background-color: white;
  padding: 24px;
}

@media screen and (max-width: 575.98px) {
  .question {
    padding: 12px;
  }
}

.question + .question {
  margin-top: 24px;
}

.question--active {
  background-color: #f5f5f5;
}

.question__title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 19px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.question__toggle {
  cursor: pointer;
  font-size: 24px;
}

@media screen and (max-width: 575.98px) {
  .question__toggle {
    font-size: 16px;
  }
}

.question__contents {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 19px;

  margin-top: 24px;
}

@media screen and (max-width: 575.98px) {
  .question__contents {
    margin-top: 12px;
  }
}
